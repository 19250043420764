<template>
  <v-container class="pt-7 pb-11">
    <v-progress-linear v-if="getLoading" height="2" indeterminate/>
    <template v-if="!getLoading && requestData">
      <v-row class="mt-0 mb-4">
        <v-col>
          <v-breadcrumbs :items="breadcrumbsItems" divider="/" class="pa-0"/>
        </v-col>
      </v-row>
      <v-row class="my-0" justify="space-between" align="center">
        <v-col cols="auto" class="text-left">
          <h6 class="text-h6 grey--text text--darken-1 mb-0">ID: {{ requestData.id }}</h6>
        </v-col>
      </v-row>
      <v-row class="mt-0 mb-4" justify="space-between" align="center">
        <v-col cols="8" class="text-left">
          <h1 class="text-h4 mb-0">{{ requestData.title }}</h1>
        </v-col>
        <v-col cols="4" class="text-right">
          <h6 class="text-h6 mb-0">
            {{ 'status'|localize }}:
            <v-icon
              :color="STATUS_COLORS[requestData.status]"
              size="20"
              class="mx-2 mb-1"
            >
            {{ STATUS_ICONS[requestData.status] }}
            </v-icon>
            <span class="font-weight-regular">{{ requestData.status|localize }}</span>
          </h6>
          <v-row v-if="isStatusUpdate" class="mt-0" justify="end">
            <v-col cols="8">
              <v-select
                v-model="newStatus"
                :items="availableStatuses"
                item-text="name"
                item-value="value"
                dense
                hide-details
                outlined
                :disabled="saveLoading"
                :placeholder="'newStatus'|localize"
                clearable
              >
                <template v-slot:item="{ item }">
                  <v-icon :color="STATUS_COLORS[item.value]" size="20" class="mr-2">{{ STATUS_ICONS[item.value] }}</v-icon>
                  <v-list-item-title colo>{{ item.value|localize }}</v-list-item-title>
                </template>
                <template v-slot:selection="{ item, index }">
                  <v-icon :color="STATUS_COLORS[item.value]" size="20" class="mr-2">{{ STATUS_ICONS[item.value] }}</v-icon>
                  <span class="grey--text">{{ item.value|localize }}</span>
                </template>
              </v-select>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row class="mt-0 mb-8" align="center" no-gutters>
        <v-col cols="auto" class="d-flex align-center">
          <div class="text-subtitle-1 mb-0">{{ 'customerReference'|localize }}:</div>
          <div class="mb-0 ml-4"> {{ requestData.request_customer_name }}</div>
        </v-col>
      </v-row>
      <div class="white elevation-2 rounded">
        <v-row class="my-0 mb-1">
          <v-col class="py-0">
            <v-tabs
              v-model="tab"
              @change="$router.replace({query: $router.currentRoute.query, hash: tab})"
              background-color="transparent"
              class="grey--text text--lighten-1"
            >
              <v-tab :ripple="false" href="#requirements" class="text-none" :disabled="saveLoading">
                {{ 'requirements'|localize }}
              </v-tab>
              <v-tab :ripple="false" href="#quotes" class="text-none" :disabled="saveLoading">
                {{ 'quotes'|localize }}
              </v-tab>
            </v-tabs>
            <v-divider/>
          </v-col>
        </v-row>
        <template v-if="tab === 'requirements'">
          <v-form ref="requestForm" v-model="requestFromValid" lazy-validation>
            <v-container class="px-4 pt-8">
              <v-row>
                <v-col cols="3">
                  <h6 class="text-subtitle-1 mb-1">{{ 'from'|localize }}</h6>
                  <h6 class="text-body-1 mb-10">{{ requestData.direction_from.full_address }}</h6>
                </v-col>
                <v-col cols="3">
                  <h6 class="text-subtitle-1 mb-1">{{ 'to'|localize }}</h6>
                  <h6 class="text-body-1 mb-10">{{ requestData.direction_to.full_address }}</h6>
                </v-col>
                <v-col cols="3">
                  <h6 class="text-subtitle-1 mb-1">{{ 'transportMode'|localize }}</h6>
                  <h6 class="text-body-1 mb-10 d-flex align-center">
                    <v-tooltip v-for="mode in requestData['transport_modes']" :key="mode" top color="black">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on" color="silver-chalice">{{ TRANSPORT_ICONS[mode] }}</v-icon>
                      </template>
                      <span>{{ mode|localize }}</span>
                    </v-tooltip>
                  </h6>
                </v-col>
                <v-col cols="3">
                  <h6 class="text-subtitle-1 mb-1">{{ 'requestType'|localize }}</h6>
                  <v-select
                    background-color="white"
                    v-model="requestData.request_type"
                    class="mt-2"
                    :items="requestTypesList"
                    item-text="name"
                    item-value="value"
                    dense
                    outlined
                    :disabled="initialRequestType !== REQUEST_TYPES.PRICE_REQUEST"
                  />
                </v-col>
              </v-row>
              <v-divider class="mb-10"/>
              <v-row class="mt-4 pb-4" justify="space-between" align="center">
                <v-col cols="auto">
                  <h5 class="text-h5 mb-2">{{ 'goods'|localize }}</h5>
                </v-col>
                <v-col cols="2" class="d-flex">
                  <v-text-field dense outlined hide-details value="1" v-model="new_goods_number"/>
                  <v-btn fab small depressed color="primary" class="white--text text-h4 text-center ml-8" @click="addGoodItem">
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <v-row class="mt-4">
                <v-col cols="12">
                  <GoodComponent
                    v-for="(item, index) in requestData.goods"
                    :currentItem="item"
                    :goodTypes="goodTypesSorted.all"
                    :requestType="requestData.request_type"
                    :transportMode="requestData['transport_modes']"
                    :item="item"
                    :number="index"
                    :key="item.id"
                    :limited="true"
                    @updateItem="updateGoodItem(item, $event)"
                    @copyItem="copyItem(item)"
                    @deleteItem="deleteItem(index)"
                  />
                </v-col>
              </v-row>
              <GoodsSummary
                :totalPackages="requestData.goods.length"
                :totalWeight="totalWeight"
                :totalVolume="totalVolume"
              />
              <v-divider class="mb-10"/>
              <v-row class="mb-10">
                <v-col cols="auto">
                  <h5 class="text-h5">{{ 'expectations'|localize }}</h5>
                </v-col>
              </v-row>
              <v-row>
                <v-col v-if="!isPriceRequest" cols="3">
                  <h6 class="text-subtitle-1 mb-4">{{ 'loading'|localize }} *</h6>
                  <v-menu
                    ref="menuStart"
                    v-model="menu_start"
                    :close-on-content-click="false"
                    :return-value.sync="requestData.loading_date"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        background-color="white"
                        :placeholder="'selectDate' | localize"
                        prepend-inner-icon="mdi-calendar"
                        v-model="loadingDate"
                        readonly
                        dense
                        outlined
                        v-on="on"
                        clearable
                        @click:clear="requestData.loading_date = []"
                        :rules="[validationRules.required]"
                      />
                    </template>
                    <v-date-picker v-model="requestData.loading_date" no-title scrollable multiple :min="today">
                      <v-spacer></v-spacer>
                      <v-btn color="primary" depressed rounded @click="menu_start = false">{{ 'cancel'|localize }}</v-btn>
                      <v-btn color="primary" depressed rounded @click="$refs.menuStart.save(requestData.loading_date)">OK</v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col v-if="!isPriceRequest" cols="3">
                  <h6 class="text-subtitle-1 mb-4">{{ 'unloading'|localize }}</h6>
                  <v-menu
                    ref="menuEnd"
                    v-model="menu_end"
                    :close-on-content-click="false"
                    :return-value.sync="requestData.unloading_date"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        background-color="white"
                        :placeholder="'selectDate' | localize"
                        prepend-inner-icon="mdi-calendar"
                        v-model="unloadingDate"
                        readonly
                        dense
                        outlined
                        v-on="on"
                        clearable
                        @click:clear="requestData.unloading_date = []"
                      />
                    </template>
                    <v-date-picker v-model="requestData.unloading_date" no-title scrollable multiple :min="today">
                      <v-spacer></v-spacer>
                      <v-btn color="primary" depressed rounded @click="menu_end = false">{{ 'cancel'|localize }}</v-btn>
                      <v-btn color="primary" depressed rounded @click="$refs.menuEnd.save(requestData.unloading_date)">OK</v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="2">
                  <h6 class="text-subtitle-1 mb-4">{{ 'deliveryTerms'|localize }} *</h6>
                  <v-select
                    background-color="white"
                    v-model="requestData.delivery_terms"
                    class="mt-2"
                    :items="deliveryTerms"
                    item-text="text"
                    item-value="value"
                    dense
                    outlined
                    :placeholder="'selectTerms' | localize"
                    :rules="[validationRules.required]"
                  />
                </v-col>
                <v-col cols="4">
                  <h6 class="text-subtitle-1 mb-4">{{ 'deadlineForResponse'|localize }}</h6>
                  <v-row>
                    <v-col cols="6">
                      <v-menu
                        ref="menuDeadline"
                        v-model="menu_deadline"
                        :close-on-content-click="false"
                        :return-value.sync="requestData.deadline_for_response_date"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            background-color="white"
                            :placeholder="'deadline_note' | localize"
                            prepend-inner-icon="mdi-calendar"
                            v-model="requestData.deadline_for_response_date"
                            readonly
                            dense
                            outlined
                            v-on="on"
                            clearable
                            @click:clear="requestData.deadline_for_response_time = ''"
                          />
                        </template>
                        <v-date-picker
                          v-model="requestData.deadline_for_response_date"
                          no-title
                          scrollable
                          :min="today"
                          @input="$refs.menuDeadline.save(requestData.deadline_for_response_date)"
                        />
                      </v-menu>
                    </v-col>
                    <v-col cols="6" v-if="requestData.deadline_for_response_date">
                      <v-select
                        background-color="white"
                        v-model="requestData.deadline_for_response_time"
                        :items="methods.intervals()"
                        item-value="key"
                        item-text="value"
                        dense
                        outlined
                        :placeholder="'selectTime' | localize"
                        prepend-inner-icon="mdi-clock"
                      />
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row v-if="!isPriceRequest">
                <v-col cols="3">
                  <h6 class="text-subtitle-1 mb-1">{{ 'slotTimeLoading'|localize }}</h6>
                  <h6 class="text-body-1 mb-10">
                    <v-row class="mt-0 mb-4">
                      <v-col cols="6">
                        <h6 class="text-subtitle-2 mb-2">{{ 'from'|localize }}</h6>
                        <v-select
                          background-color="white"
                          v-model="loadingFrom"
                          :items="methods.timeIntervals()"
                          hide-details
                          dense
                          outlined
                          prepend-inner-icon="mdi-clock"
                        />
                      </v-col>
                      <v-col cols="6">
                        <h6 class="text-subtitle-2 mb-2">{{ 'to'|localize }}</h6>
                        <v-select
                          background-color="white"
                          v-model="loadingTo"
                          :items="methods.timeIntervals()"
                          hide-details
                          dense
                          outlined
                          prepend-inner-icon="mdi-clock"
                        />
                      </v-col>
                    </v-row>
                  </h6>
                </v-col>
                <v-col cols="3">
                  <h6 class="text-subtitle-1 mb-1">{{ 'slotTimeDelivery'|localize }}</h6>
                  <h6 class="text-body-1 mb-10">
                    <v-row class="mt-0 mb-4">
                      <v-col cols="6">
                        <h6 class="text-subtitle-2 mb-2">{{ 'from'|localize }}</h6>
                        <v-select
                          background-color="white"
                          v-model="deliveryFrom"
                          :items="methods.timeIntervals()"
                          hide-details
                          dense
                          outlined
                          prepend-inner-icon="mdi-clock"
                        />
                      </v-col>
                      <v-col cols="6">
                        <h6 class="text-subtitle-2 mb-2">{{ 'to'|localize }}</h6>
                        <v-select
                          background-color="white"
                          v-model="deliveryTo"
                          :items="methods.timeIntervals()"
                          hide-details
                          dense
                          outlined
                          prepend-inner-icon="mdi-clock"
                        />
                      </v-col>
                    </v-row>
                  </h6>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="3">
                  <h6 class="text-subtitle-1 mb-1">{{ 'targetPrice'|localize }}</h6>
                  <h6 class="text-body-1 mb-10">
                    <div v-for="limit in requestData.price_limit" :key="limit.transport_mode" class="d-flex align-center">
                      <v-tooltip top color="black">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon v-bind="attrs" v-on="on" color="silver-chalice" class="mr-2">{{ TRANSPORT_ICONS[limit.transport_mode] }}</v-icon>
                        </template>
                        <span>{{ limit.transport_mode|localize }}</span>
                      </v-tooltip>
                      <i class="mr-1">{{ limit.currency }}</i> {{ limit.price_limit }}
                    </div>
                  </h6>
                </v-col>
                <v-col cols="3">
                  <h6 class="text-subtitle-1 mb-1">{{ 'priceToGuaranteedBooking'|localize }}</h6>
                  <h6 class="text-body-1 mb-10">
                    <div v-for="limit in requestData.price_limit" :key="limit.transport_mode" class="d-flex align-center">
                      <v-tooltip top color="black">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon v-bind="attrs" v-on="on" color="silver-chalice" class="mr-2">{{ TRANSPORT_ICONS[limit.transport_mode] }}</v-icon>
                        </template>
                        <span>{{ limit.transport_mode|localize }}</span>
                      </v-tooltip>
                      <template v-if="limit['price_guaranteed_booking']">
                        {{ limit.currency }} {{ limit['price_guaranteed_booking'] }}
                      </template>
                      <template v-else>--</template>
                    </div>
                  </h6>
                </v-col>
                <v-col cols="3">
                  <h6 class="text-subtitle-1 mb-1">{{ 'recurringNeeds'|localize }}</h6>
                  <h6 class="text-body-1 mb-10">{{ computedRecurring }}</h6>
                </v-col>
              </v-row>
              <v-divider class="mb-10"/>
              <v-row class="mb-10">
                <v-col cols="auto">
                  <h5 class="text-h5">{{ 'requirements'|localize }}</h5>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <h6 class="text-subtitle-1 mb-1 d-flex align-center">
                    <v-icon class="mr-1" :color="SPECIAL_REQUIREMENTS_COLORS[SPECIAL_REQUIREMENTS_TYPES.DANGEROUS_GOODS]">{{ SPECIAL_REQUIREMENTS_ICONS[SPECIAL_REQUIREMENTS_TYPES.DANGEROUS_GOODS] }}</v-icon>
                    {{ 'dangerous_goods'|localize }}
                  </h6>
                  <h6 class="text-body-1 mb-10">
                    <template v-if="requestData.dangerous_goods_number">
                      UN-{{requestData.dangerous_goods_number}} /
                      <template v-if="requestData['attachment_name']">
                        <a :href="requestData['attachment']" target="_blank">{{requestData['attachment_name']}}</a>
                      </template>
                      <template v-else>#</template>
                    </template>
                    <template v-else>--</template>
                  </h6>
                </v-col>
                <v-col cols="6">
                  <h6 class="text-subtitle-1 mb-1 d-flex align-center">
                    <v-icon class="mr-1" :color="SPECIAL_REQUIREMENTS_COLORS[SPECIAL_REQUIREMENTS_TYPES.TEMPERED_GOODS]">{{ SPECIAL_REQUIREMENTS_ICONS[SPECIAL_REQUIREMENTS_TYPES.TEMPERED_GOODS] }}</v-icon>
                    {{ 'tempered_goods'|localize }}
                  </h6>
                  <h6 class="text-body-1 mb-10">{{ requestData.tempered_goods ? `${requestData.tempered_goods[0]}ºC - ${requestData.tempered_goods[1]}ºC` : '--' }}</h6>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <h6 class="text-subtitle-1 mb-1">{{ 'goodsType'|localize }}</h6>
                  <h6 class="text-body-1 mb-10">{{ computedProductType }}</h6>
                </v-col>
                <v-col cols="3">
                  <h6 class="text-subtitle-1 mb-1">{{ 'tailLiftLoading'|localize }}</h6>
                  <h6 class="text-body-1 mb-10">
                    <template v-if="requestData.tail_lift_loading">{{ 'yes'|localize }}</template>
                    <template v-else>{{ 'no'|localize }}</template>
                  </h6>
                </v-col>
                <v-col cols="3">
                  <h6 class="text-subtitle-1 mb-1">{{ 'tailLiftDelivery'|localize }}</h6>
                  <h6 class="text-body-1 mb-10">
                    <template v-if="requestData.tail_lift_delivery">{{ 'yes'|localize }}</template>
                    <template v-else>{{ 'no'|localize }}</template>
                  </h6>
                </v-col>
              </v-row>
              <v-divider class="mb-10"/>
              <v-row>
                <v-col cols="6">
                  <h6 class="text-subtitle-1 mb-1">{{'containerLoadingUnloadingSweden'|localize}}</h6>
                  <h6 class="text-body-1 mb-10">
                    <template v-if="requestData.container_loading_unloading">{{ requestData.container_loading_unloading|localize }}</template>
                    <template v-else>--</template>
                  </h6>
                </v-col>
                <v-col cols="6">
                  <h6 class="text-subtitle-1 mb-1">{{ 'customClearance'|localize }}</h6>
                  <h6 class="text-body-1 mb-10">
                    <template v-if="requestData.custom_clearance">{{ 'needHelp'|localize }}</template>
                    <template v-else-if="requestData.custom_clearance === false">{{ 'doItOurselves'|localize }}</template>
                    <template v-else>--</template>
                  </h6>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <h6 class="text-subtitle-1 mb-1">{{ 'otherInformation'|localize }}</h6>
                  <h6 class="text-body-1 mb-10">{{ requestData.other_information ? requestData.other_information : '--' }}</h6>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" class="d-flex justify-end">
                  <v-btn
                    depressed
                    rounded
                    color="primary"
                    class="ml-2"
                    :loading="saveLoading"
                    :disabled="saveLoading"
                    @click="openSaveDialog"
                  >
                    {{ 'save'|localize }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </template>
        <template v-else>
          <AdminQuotes/>
        </template>
      </div>
      <v-dialog
        v-model="saveDialog"
        width="400"
        overlay-color="black"
        :overlay-opacity="0.3"
        @click:outside="closeSaveDialog"
      >
        <v-card class="text-center px-6">
          <v-card-title class="text-h5 d-block pt-6 pb-6 px-0" style="word-break: normal;">{{ 'saveRequest'|localize }}</v-card-title>
          <v-card-text class="text-body-1 pb-2 px-0">{{ 'saveRequestText'|localize }}</v-card-text>
          <v-card-text v-if="isNotificationUsersExist" class="text-body-1 pt-4 pb-0 px-0">
            <h6 class="text-subtitle-2 mb-2">{{ 'selectUsers'|localize }}</h6>
            <v-select
              background-color="white"
              v-model="selectedUsers"
              :items="requestData['notification_users']"
              item-value="local_id"
              hide-details
              dense
              outlined
              multiple
            >
              <template v-slot:item="{ item, on }">
                <v-list-item-title><strong>{{ item.user_name }}</strong> ({{ item.company_name }})</v-list-item-title>
              </template>
              <template v-slot:selection="{ item, index }">
                <span class="d-inline-block text-truncate" v-if="index === 0" style="max-width: 80%">
                  <strong>{{ item.user_name }}</strong>
                  ({{ item.company_name }})
                </span>
                <span v-if="index === 1" class="grey--text text-caption ml-2 mr-1">+{{ selectedUsers.length - 1 }}</span>
              </template>
            </v-select>
          </v-card-text>
          <v-card-actions class="py-6 px-0">
            <v-row>
              <v-col>
                <v-btn color="primary" block outlined rounded @click="closeSaveDialog">{{ 'back'|localize }}</v-btn>
              </v-col>
              <v-col>
                <v-btn color="primary" block depressed rounded @click="saveRequest">{{ 'save'|localize }}</v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
  </v-container>
</template>

<script>
  import {STATUS_COLORS, STATUS_ICONS, STATUSES} from '@/enums/statuses';
  import {TRANSPORT_ICONS} from '@/enums/transportMode';
  import {REQUEST_TYPES} from '@/enums/requestType';
  import goTo from 'vuetify/lib/services/goto';
  import api from '@/api/api';
  import localize from '@/filters/localize';
  import GoodComponent from '@/components/GoodComponent';
  import GoodsSummary from '@/components/GoodsSummary';
  import AdminQuotes from '@/components/AdminQuotes.vue';
  import SimpleDialog from '@/components/common/SimpleDialog.vue';
  import methods from '@/helpers/methods';
  import validationRules from '@/helpers/validationRules';
  import {
    SPECIAL_REQUIREMENTS_COLORS,
    SPECIAL_REQUIREMENTS_ICONS,
    SPECIAL_REQUIREMENTS_TYPES,
  } from '@/enums/specialRequirements';

  const initItem = {
    id: 0,
    type_of_goods: '',
    weight: '',
    length:'',
    width: '',
    height: '',
    volume: '',
    loading_meters: '',
    is_stackable: true,
    only_total_loading_meters: false,
    request_dimensions: 'dimensions',
  };

  export default {
    name: "AdminRequestDetails",
    components: {SimpleDialog, AdminQuotes, GoodsSummary, GoodComponent},

    data() {
      return {
        STATUSES,
        STATUS_ICONS,
        STATUS_COLORS,
        TRANSPORT_ICONS,
        REQUEST_TYPES,
        validationRules,
        methods,
        requestId: this.$router.currentRoute.query.request_id,
        requestFromValid: true,
        getLoading: false,
        requestData: null,
        initialRequestType: null,
        tab: this.initialHash(),
        newStatus: null,
        saveLoading: false,
        menu_start: false,
        menu_end: false,
        menu_deadline: false,
        saveDialog: false,
        selectedUsers: [],
        new_goods_number: 1,
      };
    },

    async mounted() {
      try {
        this.getLoading = true;
        let newRequestData = await api.getSingleAdminRequest(this.requestId);
        this.requestData = newRequestData.data;
        this.initialRequestType = newRequestData.data.request_type;
        this.getLoading = false;
      } catch (e) {
        this.getLoading = false;
      }
    },

    computed: {
      SPECIAL_REQUIREMENTS_ICONS() {
        return SPECIAL_REQUIREMENTS_ICONS
      },
      SPECIAL_REQUIREMENTS_TYPES() {
        return SPECIAL_REQUIREMENTS_TYPES
      },
      SPECIAL_REQUIREMENTS_COLORS() {
        return SPECIAL_REQUIREMENTS_COLORS
      },
      productTypes() {
        return this.$store.getters.productTypes;
      },
      recurringIntervals() {
        return this.$store.getters.recurringIntervals;
      },
      goodTypesSorted() {
        return this.$store.getters.goodTypesSorted;
      },
      deliveryTerms() {
        return this.$store.getters.deliveryTerms;
      },
      requestTypesList() {
        return this.$store.getters.requestTypesList;
      },
      isPriceRequest() {
        return this.requestData.request_type === REQUEST_TYPES.PRICE_REQUEST;
      },
      totalWeight() {
        return methods.totalWeight(this.requestData.goods);
      },
      totalVolume() {
        return methods.totalVolume(this.requestData.goods);
      },
      today() {
        return new Date().toISOString().slice(0, 10);
      },
      isNotificationUsersExist() {
        return this.requestData.notification_users?.length > 0
      },
      isStatusUpdate() {
        return this.requestData.status === STATUSES.NEW ||
          this.requestData.status === STATUSES.CLOSED ||
          this.requestData.status === STATUSES.NOT_BOOKED
      },
      availableStatuses() {
        let statuses = this.$store.getters.statuses;
        return statuses.reduce((array, status) => {
          if (this.requestData.status === STATUSES.NEW) {
            if (
              status.value === STATUSES.BOOKED ||
              status.value === STATUSES.CLOSED
            ) {
              array.push(status);
            }
          }
          if (
            this.requestData.status === STATUSES.CLOSED ||
            this.requestData.status === STATUSES.NOT_BOOKED
          ) {
            if (status.value === STATUSES.BOOKED) {
              array.push(status);
            }
          }
          return array;
        }, []);
      },
      breadcrumbsItems() {
        return [
          {
            text: localize('requests'),
            disabled: false,
            to: {name: 'admin-requests'}
          },
          {
            text: localize('requestDetails'),
            disabled: true,
          },
        ]
      },
      computedRecurring() {
        if (this.requestData.recurring) {
          let existCheck = this.recurringIntervals.findIndex(interval => interval.key === this.requestData.recurring);
          if (existCheck >= 0) {
            return localize(this.requestData.recurring);
          } else {
            return this.requestData.recurring;
          }
        }
        return '--';
      },
      computedProductType() {
        if (this.requestData.goods_type) {
          let existCheck = this.productTypes.findIndex(type => type.key === this.requestData.goods_type);
          if (existCheck >= 0) {
            return localize(this.requestData.goods_type);
          } else {
            return this.requestData.goods_type;
          }
        }
      },
      loadingDate: {
        get: function () {
          return this.requestData.loading_date ? this.requestData.loading_date.join(', ') : '';
        },
        set: function () {
        },
      },
      unloadingDate: {
        get: function () {
          return this.requestData.unloading_date ? this.requestData.unloading_date.join(', ') : '';
        },
        set: function () {
        },
      },
      loadingFrom: {
        get: function() {
          return this.requestData.slot_time_loading?.from ? this.requestData.slot_time_loading.from : ''
        },
        set: function(newValue) {
          if (!this.requestData.slot_time_loading) {
            this.requestData.slot_time_loading = {from: '', to: ''};
          }
          this.requestData.slot_time_loading.from = newValue;
        }
      },
      loadingTo: {
        get: function() {
          return this.requestData.slot_time_loading?.to ? this.requestData.slot_time_loading.to : ''
        },
        set: function(newValue) {
          if (!this.requestData.slot_time_loading) {
            this.requestData.slot_time_loading = {from: '', to: ''};
          }
          this.requestData.slot_time_loading.to = newValue;
        }
      },
      deliveryFrom: {
        get: function() {
          return this.requestData.slot_time_delivery?.from ? this.requestData.slot_time_delivery.from : ''
        },
        set: function(newValue) {
          if (!this.requestData.slot_time_delivery) {
            this.requestData.slot_time_delivery = {from: '', to: ''};
          }
          this.requestData.slot_time_delivery.from = newValue;
        }
      },
      deliveryTo: {
        get: function() {
          return this.requestData.slot_time_delivery?.to ? this.requestData.slot_time_delivery.to : ''
        },
        set: function(newValue) {
          if (!this.requestData.slot_time_delivery) {
            this.requestData.slot_time_delivery = {from: '', to: ''};
          }
          this.requestData.slot_time_delivery.to = newValue;
        }
      }
    },

    methods: {
      updateGoodItem(item, newData) {
        item[newData.name] = newData.value;
      },
      copyItem(item) {
        this.requestData.goods.push({...item, id: Math.floor(Date.now() * Math.random())});
      },
      deleteItem(index) {
        this.requestData.goods.splice(index, 1);
        if (this.requestData.goods.length === 0) {
          this.resetGoods();
        }
      },
      resetGoods() {
        this.requestData.goods = [{...initItem}];
      },
      addGoodItem() {
        for (let i = 0; i < this.new_goods_number; i++) {
          this.requestData.goods.push({...initItem, id: Math.floor(Date.now() * Math.random())});
        }
      },
      setDeadlineDate() {
        let newDate = new Date(new Date().setDate(new Date().getDate() + 10));
        let year = newDate.getFullYear();
        let month = ('0' + (newDate.getMonth() + 1)).slice(-2);
        let day = ('0' + newDate.getDate()).slice(-2);
        return `${year}-${month}-${day}`;
      },
      formValidation() {
        if (!this.$refs.requestForm.validate()) {
          setTimeout(() => {
            const el = document.querySelector('.v-messages.theme--light.error--text').closest('.col');
            goTo(el);
          }, 300);
          return false;
        } else {
          return true;
        }
      },
      openSaveDialog() {
        if (this.formValidation()) {
          if (this.requestData.notification_users?.length > 0 ) {
            let localUsers = [];
            this.requestData.notification_users.forEach((item) => {
              item['local_id'] = crypto.randomUUID();
              localUsers.push(item['local_id']);
            });
            this.selectedUsers = localUsers;
          }
          this.saveDialog = true;
        }
      },
      closeSaveDialog() {
        this.selectedUsers = [];
        this.saveDialog = false;
      },
      queryData() {
        let queryData = {};
        let localGoods = [];
        this.newStatus && (queryData.status = this.newStatus);
        this.requestData.deadline_for_response_date ? (queryData.deadline_for_response_date = this.requestData.deadline_for_response_date) : queryData.deadline_for_response_date = this.setDeadlineDate();
        this.requestData.deadline_for_response_time && (queryData.deadline_for_response_time = this.requestData.deadline_for_response_time);
        queryData.request_type = this.requestData.request_type;
        this.requestData.goods.forEach(goodItem => {
          let localGoodItem = {...goodItem};
          delete localGoodItem.id;
          localGoods.push(localGoodItem);
        });
        queryData.goods = [...localGoods];
        queryData.total_of_package = this.requestData.goods.length;
        queryData.weight = this.totalWeight;
        queryData.volume = this.totalVolume;
        queryData.delivery_terms = this.requestData.delivery_terms;
        if (this.selectedUsers) {
          let localUsers = [];
          this.requestData.notification_users.forEach((item) => {
            let isSelected = this.selectedUsers.findIndex(findItem => findItem === item['local_id']);
            if (isSelected >= 0) {
              localUsers.push({'user_id': item.user_id, 'company_id': item.company_id});
            }
          });
          queryData.notification_users = [...localUsers]
        }
        if (!this.isPriceRequest) {
          queryData.loading_date = this.requestData.loading_date;
          this.requestData.unloading_date?.length && (queryData.unloading_date = this.requestData.unloading_date);
          this.requestData.slot_time_loading && (queryData.slot_time_loading = {
            from: this.requestData.slot_time_loading.from,
            to: this.requestData.slot_time_loading.to,
          })
          this.requestData.slot_time_delivery && (queryData.slot_time_delivery = {
            from: this.requestData.slot_time_delivery.from,
            to: this.requestData.slot_time_delivery.to,
          })
        }
        return queryData;
      },
      async saveRequest() {
        this.saveDialog = false;
        try {
          this.saveLoading = true;
          await api.updateAdminRequest(this.requestId, this.queryData());
          let remoteSingleData = await api.getSingleAdminRequest(this.requestId);
          this.requestData = remoteSingleData.data;
          this.saveLoading = false;
          methods.showGlobalSnackbar('success', localize('done'));
        } catch (e) {
          this.saveLoading = false;
          methods.showGlobalSnackbar('error', methods.getErrors(e.response));
        }
      },
      initialHash() {
        const queryHash = this.$router.currentRoute.hash.slice(1);
        return queryHash ? queryHash : 'requirements';
      },
    }
  };
</script>
