<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="offers"
      :loading="loading"
      loader-height="2"
      :sort-by="defaultSortBy"
      :sort-desc="defaultSortDesc"
      :options.sync="options"
      :server-items-length="totalItems"
      :no-data-text="'noDataAvailable'|localize"
      hide-default-footer
    >
      <template v-slot:item.proposal="{ item }">
        {{ item['proposal'] }} <i class="text-body-2">{{ item['currency'] }}</i>
        /
        {{ item['company_price'] }} <i class="text-body-2">{{ item['currency'] }}</i>
      </template>
      <template v-slot:item.lead_time="{ item }">
        {{ item['lead_time'] }} {{ leadTimeDays(item['lead_time']) }}
      </template>
      <template v-slot:item.emissions="{ item }">
        {{ item['emissions'] ? item['emissions'] : '--' }}
      </template>
      <template v-slot:item.valid_to="{ item }">
        <div v-html="$options.filters.formatDate(item['valid_to'])"/>
      </template>
      <template v-slot:item.transport_mode_shortcode="{ item }">
        <v-tooltip top color="black">
          <template v-slot:activator="{ on, attrs }">
            <v-icon class="ml-7" v-bind="attrs" v-on="on" color="silver-chalice">
              {{ TRANSPORT_ICONS[item['transport_mode_shortcode']] }}
            </v-icon>
          </template>
          <span>{{ item['transport_mode_shortcode']|localize }}</span>
        </v-tooltip>
      </template>
      <template v-slot:item.comment="{ item }">
        <v-tooltip v-if="item.comment" top color="black" max-width="320">
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" color="success" v-on="on" size="20">mdi-comment</v-icon>
          </template>
          <span>{{ item.comment }}</span>
        </v-tooltip>
        <v-tooltip v-if="item['reopen_comment']" top color="black" max-width="320">
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on" color="warning" size="20">mdi-comment</v-icon>
          </template>
          <div>
            <div class="mb-2"><strong>{{ 'reopenComment'|localize }}:</strong></div>
            {{ item['reopen_comment'] }}
          </div>
        </v-tooltip>
      </template>
      <template v-slot:item.attachments="{ item }">
        <v-tooltip v-for="fileData in item['attachments']" :key="fileData['attachment_name']" top color="black" max-width="320">
          <template v-slot:activator="{ on, attrs }">
            <a :href="fileData['attachment_link']" target="_blank" style="text-decoration: none">
              <v-icon v-bind="attrs" color="success" v-on="on" size="20">mdi-file</v-icon>
            </a>
          </template>
          <span>{{ fileData['attachment_name'] }}</span>
        </v-tooltip>
      </template>
      <template v-slot:item.status="{ item }">
        <v-btn
          v-if="item.status === STATUSES.BOOKED"
          class="justify-center text-subtitle-2"
          rounded
          fab
          x-small
          depressed
          color="success"
          :to="{name: 'booking-summary', query: {request_id: requestId, proposal_id: item.id}}"
        >
          <v-tooltip top color="black">
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on" size="20">mdi-book-open-variant</v-icon>
            </template>
            <span>{{ 'bookingSummaryOpen'|localize }}</span>
          </v-tooltip>
        </v-btn>
        <div v-else class="d-flex align-center justify-center">
          <v-icon :color="STATUS_COLORS[item.status]" size="20" class="mr-2">{{ STATUS_ICONS[item.status] }}</v-icon>
          <span>{{ item.status|localize }}</span>
        </div>
      </template>
      <template v-slot:item.settings="{ item }">
        <v-menu v-if="item.status === STATUSES.EXPIRED || item.status === STATUSES.NOT_BOOKED" left transition="slide-y-transition">
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" icon :ripple="false">
              <v-icon size="20">mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item v-if="item.status === STATUSES.EXPIRED" link @click="$refs.reopenDialog.openDialog(); reopenId = item.id">
              <v-list-item-title>{{ 'reopen'|localize }}</v-list-item-title>
            </v-list-item>
            <v-list-item v-if="item.status === STATUSES.EXPIRED || item.status === STATUSES.NOT_BOOKED" link :to="{name: 'booking', query: {offer_id: item.id, request_id: requestId, mode: item['transport_mode_shortcode']}}">
              <v-list-item-title>{{ 'book'|localize }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </v-data-table>
    <v-pagination
      color="primary"
      v-model="currentPage"
      :length="totalPages"
      :total-visible="7"
      class="py-4"
      :disabled="loading"
      @input="offers = [];getOffers();"
    />
    <ReopenDialog :offerId="reopenId" @updateOffers="getOffers" ref="reopenDialog"/>
  </div>
</template>

<script>
  import {TRANSPORT_ICONS} from '@/enums/transportMode';
  import {STATUSES, STATUS_COLORS, STATUS_ICONS} from '@/enums/statuses';
  import localize from '@/filters/localize';
  import api from '@/api/api';
  import ReopenDialog from '@/components/ReopenDialog.vue';

  export default {
    name: "AdminQuotes",
    components: { ReopenDialog },

    data() {
      return {
        TRANSPORT_ICONS,
        STATUSES,
        STATUS_COLORS,
        STATUS_ICONS,
        requestId: this.$router.currentRoute.query.request_id,
        loading: true,
        defaultSortBy: 'date_proposal',
        defaultSortDesc: true,
        options: {},
        itemsPerPage: 20,
        currentPage: 1,
        totalPages: 0,
        totalItems: 0,
        offers: [],
        reopenId: null
      };
    },

    watch: {
      options: {
        handler() {
          this.clearOffers();
          this.getOffers();
        },
        deep: true,
      },
    },

    computed: {
      headers() {
        return [
          {text: localize('quoteDate'), value: 'date_proposal', width: 120},
          {text: localize('transportCompany'), value: 'company'},
          {text: localize('quote_topic'), value: 'proposal'},
          {text: localize('leadTime'), value: 'lead_time', width: 90},
          {text: localize('emissions') + ', kg', value: 'emissions', width: 90},
          {text: localize('quoteValidTo'), value: 'valid_to', width: 155},
          {text: localize('transportMode'), value: 'transport_mode_shortcode'},
          {text: localize('comments'), value: 'comment', align: 'center', width: 130},
          {text: localize('uploadedFiles'), value: 'attachments'},
          {text: '', value: 'status', align: 'center', width: 170, sortable: false},
          {text: '', value: 'settings', sortable: false, align: 'center'},
        ];
      }
    },

    methods: {
      clearOffers() {
        this.offers = [];
        this.totalPages = 0;
        this.totalItems = 0;
        this.currentPage = 1;
      },
      leadTimeDays(item) {
        return item > 1 ? localize('days') : localize('day')
      },
      queryData() {
        let queryData = {};
        queryData.request_id = this.requestId;
        queryData.per_page = this.itemsPerPage;
        queryData.page = this.currentPage;
        this.options.sortBy[0] && (
          queryData['sort'] = {
            field: this.options.sortBy[0],
            desc: this.options.sortDesc[0],
          });
        return queryData;
      },
      setOffersData(requestsData) {
        this.offers = requestsData.data.data;
        this.totalPages = requestsData.data.pagination['total_page'];
        this.totalItems = requestsData.data.pagination['total_items'];
      },
      async getOffers() {
        try {
          this.loading = true;
          let requestsData = await api.getRequestOffers(this.queryData());
          this.setOffersData(requestsData);
          this.loading = false;
        } catch (e) {
          this.loading = false;
        }
      },
    },
  };
</script>
